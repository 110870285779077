import * as _ from 'lodash';

import {ALTERENATE_URL, BASE_URL, BASE_URL_SSL, ENABLE_THUMBOR, IMG_URL, NODE_ENV} from "@/config/config";

export const ___t = (data: any, locale: string) => {
  const translation = _.filter(data, {"locale": locale});
  return translation.length ? translation[0] : data[0] || {locale: 'en', name: '', description: ''};
}

export const __asset = (path: string, width: number, height: number) => {
  if (ENABLE_THUMBOR === 'true') {
    path = path.replace(BASE_URL +'/storage/', '');
    path = path.replace(BASE_URL_SSL +'/storage/', '');
    path = path.replace('https://localhost:8000/storage/', '');
    return IMG_URL + `/unsafe/${width}x${height}/` + path;
  } else
    return path;
}

export const __assetFilledBlur = (path: string, width: number, height: number) => {
  if (ENABLE_THUMBOR === 'true') {
    path = path.replace(BASE_URL +'/storage/', '');
    path = path.replace(BASE_URL_SSL +'/storage/', '');
    path = path.replace('https://localhost:8000/storage/', '');
    return IMG_URL + `/unsafe/fit-in/${width}x${height}/filters:fill(blur)/` + path;
  } else
    return path;
}

export const __public_asset = (path: string) => {
    if(NODE_ENV === 'development') {
      path = path.replace(BASE_URL + '/storage/',
        ALTERENATE_URL + '/storage/');
    }
    return path;
}
