<template>
  <div class="card card-user">
    <div class="image">
      <img src="/static/img/background/damir-bosnjak.jpg" alt="...">
    </div>
    <div class="card-body">
      <div class="author">
        <a href="#">
          <img class="avatar border-gray" src="/static/img/mike.jpg" alt="...">
          <h5 class="title">Mohamed Gamal</h5>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  export default {}

</script>
<style>

</style>
