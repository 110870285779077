export const MAX_UPLOAD_FILE_SIZE = 10;

export const DBDataTypes = {
  hotel: 'Hotel',
  business: 'Business',
  event: 'Event',
  service: 'Service',
  category: 'Category',
  item: 'Item',
  media: 'Media',
  user: 'User',
  price: 'Price',
  contact: 'Contact'
}
